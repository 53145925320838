import React from 'react'

import { YTDPage } from '../../components'

class Reports extends React.Component {
  render() {
    return <YTDPage />
  }
}

export default Reports
